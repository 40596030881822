import React from 'react';
import Hand from '../assets/hand.png';
import Brain from '../assets/brain.svg';
import Mac from '../assets/im_mackbook.png';
import { Element, animateScroll as scroll } from 'react-scroll';
const Products = () => {
  return (
    <div className='flex flex-col justify-between bg-with-image h-[990px] mobile:h-[549px] overflow-hidden '>
      <div className='flex flex-col gap-4 mt-6 ml-6'>
        <div className='flex gap-4 items-center'>
          <img src={Brain} alt="Brain" />
          <p className='text-4xl text-white font-bold mobile:text-xl'>
            <Element name='Products'>
              Продукт
            </Element>
            </p>
        </div>
        <div className='max-w-[672px] h-auto'>
          <p className='font-medium text-3xl text-white mobile:text-base'>Система поддержки принятия решений для врача-реабилитолога с использованием искусственного интеллекта Human Recovery</p>
        </div>
        <a href='https://human-recovery.kz'>
          <button className='max-w-[325px] w-full h-20 rounded-3xl bg-[#FF9400] text-2xl text-white
          mobile:w-[228px]
          mobile:h-[58px]
          mobile:text-lg
          '>Узнать подробнее</button>
        </a>
      </div>
      <div className='flex justify-end relative'>
        <img className='flex self-end max-w-[1053px] w-full h-auto absolute left-[615px] mobile:w-[421px] mobile:h-[256px] mobile:left-[125px]' src={Mac} alt="Mac" />
      </div>
    </div>
  );
}

export default Products;