import React, { useState, useRef } from 'react';
import im2 from '../assets/medical.png';
import im1 from '../assets/im_1.png';
import im3 from '../assets/im_3.png';
import im5 from '../assets/ph.png'
import circle from '../assets/Circle.png'
import TakeModal from '../Header/TakeModal';
import { Element, animateScroll as scroll } from 'react-scroll';
const InfTechnologies = () => {
  const [takeActive, setTakeActive] = useState (false)
  const textRef = useRef(null)
    const textRef2 = useRef(null)
    const textRemove = () =>{
        if(textRef.current.value){
            textRef.current.value = '';
            textRef2.current.value = '';
        }
    }
  return (
    <div className="flex justify-between  mt-24 mobile:flex-wrap mobile:h-[823px]">
      <div className='flex mobile:flex-wrap mobile:h-[390px]'>
            <span className='absolute text-6xl font-bold mobile:text-3xl
            1330:text-5xl
            '>
                  Информационные технологии в <br/> медицине
                  <Element class='home' name="home">
                    <p className='text-[32px] mt-4 font-normal mobile:text-xl
                    1330:text-2xl
                    '>Инновационные IT-решения сфере <br/> здравоохранения</p>
                  </Element>
            </span>
            <div className='flex flex-col self-center  relative'>
                    <img className='flex self-end w-[555px] mt-32 h-[280px] mobile:w-[274px] mobile:h-[144px]
                    1330:w-[400px]
                    ' src={im3}/>
                    <img onClick={() => setTakeActive(true)} className='absolute left-[428px] top-[260px] w-[174px] h-[182px] animate-spin-slow z-3 mobile:w-[109px] mobile:h-[109px] mobile:left-[205px] mobile:top-[220px]
                    1330:w-[99px]
                    1330:h-[99px]
                    1330:left-[348px]
                    ' src={circle}/> 
                    <a href='https://science-fund.kz' >
                        <img className='absolute bottom-[-160px] mobile:bottom-[-90px] max-w-[325px] w-full max-h-[24] mobile:w-[162px] mobile:h-12
                        1330:w-[205px]
                        ' src={im1}/>
                    </a>
            </div>
      </div>
      <div className='relative'>
        <img className="max-w-[670px] w-full max-h-[677px] mobile:w-[343px] mobile:hidden mobile:h-[293px]
        1330:w-[500px]
        " src={im2} alt="Medical Technology"/>
        <img className='1330:hidden mobile:block 2050:hidden rounded-xl' src={im5}/>
      </div>
      <TakeModal className="mobile:hidden" active ={takeActive} setActive={setTakeActive}>
                    <div className='flex flex-col gap-8 px-6 py-12' >
                        <p className='text-xl font-bold'>
                            Запрос консультации
                        </p>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm font-semibold'>Имя</p>
                            <input ref={textRef} className='w-full h-11 border border-gray-400 rounded-xl pl-4' placeholder='Напишите Ваше имя' type='text'/>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm font-semibold'>Номер телефона</p>
                            <input ref={textRef2} className='w-full h-11 border border-gray-400 rounded-xl pl-4' placeholder='+7 707 777 77-77' type='text'/>
                        </div>
                        <p className='text-xs '>
                            Нажимая на кнопку "Получить консультацию", я даю Согласие на обработку Персональных данных и принимаю Политику обработки Персональных данных.
                        </p>
                        <button onClick={textRemove} className='w-[288px] h-[45px] bg-[#FF9400] text-white flex justify-center items-center rounded-xl self-center'>
                            Получить консультацию
                        </button>
                    </div>
                </TakeModal>
    </div>
  );
}

export default InfTechnologies;
