import React, { useRef } from 'react';
import logo from '../assets/logo2.png'
import WhatsApp from '../assets/whatsapp.svg'
import Facebook from '../assets/facebook.svg'
import In from '../assets/linkedin.svg'
import Instagram from '../assets/instagram.svg'
import Message from '../assets/Message.svg'
import Phone from '../assets/phone.svg'
import Map from '../assets/map-Pin.svg'
import Mail from '../assets/mail.svg'
const Footer = () => {
  const writeRef = useRef(null)
  const Notif = () =>{
    if(writeRef.current.value !== ''){
      alert("Спасибо за то, что подписались на нашу рассылку! Мы рады, что вы хотите быть в курсе последних новостей и обновлений. Ожидайте интересные новости и полезные советы в ближайшие дни.")
    }
  }
  return (
    <footer className='bg-white'>
        <div className='flex justify-between px-10 py-10 mobile:flex-wrap mobile:px-4 mobile:gap-6'>
            <div className='flex flex-col gap-[52px] max-w-[440px] w-full'>
              <div className='flex flex-col gap-4'>
                <img className='w-[168px] h-[30px]' src={logo}/>
                <p className='text-xs text-[#BFBCBC]'>Все права на материалы, представленные на нашем сайте принадлежат компании SanaMed и предназначены только для ознакомления</p>
              </div>
              <div className='flex flex-col gap-4'>
                <p className='font-semibold mobile:leading-[130%] mobile:text-[12px] leading-[110%]'>Подпишитесь на нашу рассылку, чтобы быть в курсе новинок, обновлений продуктов и отраслевых новостей!</p>
                <div className='flex justify-between items-center h-auto py-2 border border-[#FAE8CC] w-full rounded-2xl px-4 mobile:flex-wrap gap-2'>
                  <input ref={writeRef} type='text' className='max-w-[224px] h-[54px] w-full text-[14px] ' placeholder='Напишите e-mail'/>
                  <button className='flex justify-center items-center max-w-[137px] w-full h-[45px] bg-[#FF9400] text-white rounded-xl mobile:w-[113px] mobile:h-[37px] mobile:text-sm mobile:px-3'
                  onClick={Notif}
                  >Подписаться</button>
                </div>
              </div>
            </div>
            <div className='max-w-[324px] w-full'>
              <ul className='flex flex-col gap-4 mobile:text-sm'>
                <li>О Компании</li>
                <li>Миссия и ценности</li>
                <li>Услуги</li>
                <li>Продукт</li>
                <li>Партнеры</li>
                <li>Представители</li>
              </ul>
            </div>
            <div className='flex flex-col gap-3 max-w-[439px] w-full'>
              <ul className='flex flex-col gap-6'>
                <li className='mobile:text-xs flex gap-2'>
                  <img src={Map} alt="" />
                  Республика Казахстан, город Астана, Алматинский район, ул. Жумекен Нажимеденов 13-482.  
                </li>
                <li className='flex gap-2'>
                  <img src={Phone} alt="" />
                  +7 701 336 83 09
                </li>
                <li className='flex gap-2'>
                  <img src={Mail} alt="" />
                  sanamed@list.ru
                </li>
                <li className='font-semibold mobile:text-sm'>
                  Оставайтесь на связи – подписывайтесь на нас!
                </li>
              </ul>
              <div className='flex gap-5 justify-center'>
                <a href='https://www.facebook.com/profile.php?id=61561146504083&mibextid=LQQJ4d'>
                 <img className='w-8 h-8' src={Facebook}/>
                </a>
                <a href='https://www.linkedin.com/company/sanamed-kz/'>
                  <img className='w-8 h-8' src={In}/>
                </a>
                <a href='https://www.instagram.com/toosanamed?igsh=cjk4eHp6eGJ1NHRm'>
                  <img className='w-8 h-8' src={Instagram}/>
                </a>
                <a href='https://wa.me/77021716395?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C.%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D1%8E%D1%82%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8'>
                  <img className='w-8 h-8' src={WhatsApp}/>
                </a>
              </div>
            </div>
            <p className='text-[12px] text-gray-500 flex justify-center items-center  w-full'>
            Политика конфиденциальности
            </p>
        </div>
    </footer>
  );
}

export default Footer;
