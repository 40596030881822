import React, {useRef, useEffect} from 'react';
import People from '../assets/People.svg'
import QazMed from '../assets/logo-qazmedpro.png'
// import QazMed from '../assets/mobileLogoQazMed.png'
import Numetek from '../assets/logo-numetek.png'
import Agp from '../assets/logo-AGP.png'
import TOO from '../assets/ТОО “Оздоровительный центр Раяна”.png'
import { Element, animateScroll as scroll } from 'react-scroll';
const Partners = () => {
    const carouselRef = useRef(null);

  useEffect(() => {
    const scroll = () => {
      if (carouselRef.current) {
        if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 2) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scroll,20);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='px-10 flex flex-col gap-10 mobile:px-4'>
        <div className='flex gap-2.5'>
            <img src={People}/>
            <p className='font-bold text-4xl mobile:text-xl'>
                <Element name='Partners'>
                    Наши партнеры
                </Element>
            </p>
        </div>
        <div className="overflow-hidden">
        <div
            ref={carouselRef}
            className="flex whitespace-nowrap overflow-hidden gap-5 carousel"
            style={{ willChange: 'transform' }}
        >
            <img src={QazMed} alt="QazMedPro" className="h-20 mx-2" />
            <img src={Numetek} alt="Numetek Group" className="h-20 mx-2" />
            <img src={Agp} alt="AGP" className="h-20 mx-2" />
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "Оздоровительный центр <br/> Раяна"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO ТОО "ML-Technology"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "ТурарАгроСервис"</p>
            </div>
            <img src={QazMed} alt="QazMedPro" className="h-20 mx-2" />
            <img src={Numetek} alt="Numetek Group" className="h-20 mx-2" />
            <img src={Agp} alt="AGP" className="h-20 mx-2" />
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "Оздоровительный центр <br/> Раяна"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO ТОО "ML-Technology"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "ТурарАгроСервис"</p>
            </div>
            <img src={QazMed} alt="QazMedPro" className="h-20 mx-2" />
            <img src={Numetek} alt="Numetek Group" className="h-20 mx-2" />
            <img src={Agp} alt="AGP" className="h-20 mx-2" />
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "Оздоровительный центр <br/> Раяна"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO ТОО "ML-Technology"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "ТурарАгроСервис"</p>
            </div>
            <img src={QazMed} alt="QazMedPro" className="h-20 mx-2" />
            <img src={Numetek} alt="Numetek Group" className="h-20 mx-2" />
            <img src={Agp} alt="AGP" className="h-20 mx-2" />
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "Оздоровительный центр<br/> Раяна"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "ML-Technology"</p>
            </div>
            <div className='flex items-center justify-center'>
                <p className='text-xl font-semibold text-center'>TOO "ТурарАгроСервис"</p>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Partners;
