import React from 'react';
import logoBig from '../assets/logoBig.png'
import PDF from '../public/SanaMed.pdf'
const MoreInf = () => {
  return (
    <div className='px-10 py-20 flex relative mobile:flex-wrap mobile:flex-col-reverse mobile:h-[581px]'>
        <div>
            <div className='flex flex-col gap-8 max-w-[576px]'>
                <p className='font-medium text-3xl mobile:text-base'>Получите подробную информацию о компании SanaMed и её продуктах, скачав нашу презентацию.</p>
                <a href={PDF} // Используйте абсолютный путь
                  className="href" 
                  download="SanaMed_Presentation.pdf" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className='max-w-[325px] w-full h-20 tex-white text-2xl bg-[#FF9400] rounded-3xl text-white mobile:w-[228px] mobile:h-[58px] mobile:text-lg'>Скачать презентацию</button>                  
                </a>
            </div>
        </div>
        <div className='flex items-center mobile:items-start justify-center max-w-[900px] w-full h-[260px] mobile:h-36 mobile:relative'>
            <div className='flex justify-center items-center w-[286px] h-[55px] bg-[#FFE9CA] rounded-2xl absolute top-[88px] left-[678px] px-2 
            
            mobile:left-0 
            mobile:top-[-48px]
            mobile:w-[156px]
            mobile:h-9
            mobile:text-xs
            mobile:px-2
            mobile:text-center
            '
            style={{borderRadius:"20px 20px 0px 20px"}}><p>Ориентированность на пациента</p></div>
            <div className='flex justify-center items-center w-[286px] h-[55px] leading-[110%] bg-[#FFE9CA] rounded-2xl absolute top-[96px] left-[1021px] px-2 text-center
            mobile:left-[149px] 
            mobile:top-[-100px]
            mobile:w-[170px]
            mobile:h-9
            mobile:text-xs
            mobile:px-2
            mobile:text-center
            '
            style={{borderRadius:"20px 20px 20px 0px"}}
            ><p>Аналитика данных и искусственный интеллект</p></div>
            <img className='w-[281px] h-[66px] mobile:w-[169px] mobile:h-[40px]' src={logoBig}/>
            <div className='flex justify-center items-center w-[286px] h-[55px] bg-[#FFE9CA] absolute top-[250px] left-[1055px] px-2 text-center
            mobile:left-[153px]
            mobile:top-[46px]
            mobile:w-[162px]
            mobile:h-9
            mobile:text-xs
            mobile:px-2
            mobile:text-center
            '
            style={{borderRadius:"0px 20px 20px 20px"}}
            ><p>Передовые технологии</p></div>
            <div className='flex leading-[110%] justify-center items-center w-[286px] h-[55px] bg-[#FFE9CA] rounded-2xl absolute top-[269px] left-[596px] px-2 text-center
            mobile:left-0 
            mobile:top-[99px]
            mobile:w-[179px]
            mobile:h-9
            mobile:text-xs
            mobile:px-2
            mobile:text-center
            '
            style={{borderRadius:"20px 0px 20px 20px"}}><p>Медицинские информационные системы</p></div>
        </div>
    </div>
  );
}

export default MoreInf;
