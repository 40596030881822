import Global from '../assets/Global.svg'
import logo from '../assets/logo.png'
import Burger from '../assets/burger.svg'
import { useRef, useState } from 'react'
import './modal.css'
import Modal from './Modal'
import TakeModal from './TakeModal'
import emailjs from '@emailjs/browser';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
const Header = () => {
    const form = useRef();
    const [modalActive, setModalActive] = useState(false);
    const [takeActive, setTakeActive] = useState(false);
    const [name, setName] =useState(null)
    const [mobile, setMobile] = useState(null)
    const textRef = useRef(null)
    const textRef2 = useRef(null)
    const textRemove = () =>{
        if(textRef.current.value){
            textRef.current.value = '';
            textRef2.current.value = '';
        }
    }

    const sendEmail = (e) =>{
        e.preventDefault();

        const serviceID = 'service_ocmu9qk'
        const publicKey='-_Ap9uu91e1RkpZHh'
        const templateID='template_50k38ek'

        const templateParams = {
            from_name:name,
            mobile_phone:mobile,
            to_name:"SanaMed",
            
          }
        emailjs.send(serviceID, templateID, templateParams,publicKey)
            .then((response) =>{
                console.log('SUCCESS!' , response);
                alert("Спасибо! Ваша заявка в обработке")
                setName('');
                setMobile('');
            },(error)=>{
                console.log('FAILED!', error.text)
            })
    }
  return (
    <header>
        <div className="fixed max-w-[1420px] w-full  z-20 mx-4 left-0 h-[65px] flex justify-center items-center bg-white rounded-2xl 
        mobile:py-4
        mobile:mx-0
        mobile:ml-2
        mobile:w-[410px]
        ">
            <div className="flex justify-between items-center h-11 px-2.5 py-2.5 w-full">
                <div className='mobile:relative mobile:z-10'><img src={logo}/></div>
                <div>
                    <div className='hidden mobile:flex mobile:relative mobile:z-10'>
                        <img  onClick={() => setModalActive(true)} src={Burger} />
                    </div>
                    <Modal active={modalActive} setActive={setModalActive}>
                        <div className='flex flex-col h-full gap-6 items-center justify-center'>
                            <ul className='flex flex-col items-center justify-between gap-2 max-w-[739px] w-full
                            
                            ' >
                                <li><Link to="home" smooth={true} duration={500}>О Компании</Link></li>
                                <li><Link to="Mission" smooth={true} duration={500}>Миссия и ценности</Link></li>
                                <li><Link to="Services" smooth={true} duration={500}>Услуги</Link></li>
                                <li><Link to="Products" smooth={true} duration={500}>Продукт</Link></li>
                                <li><Link to="Partners" smooth={true} duration={500}>Партнеры</Link></li>
                                <li><Link to="Representatives" smooth={true} duration={500}>Представители</Link></li>
                            </ul>
                            <div className='flex items-center gap-3 '>
                                <div><img src={Global}/></div>
                                <div>rus</div>
                            </div>
                            <button className='max-w-[184px] w-full h-11 rounded-2xl bg-orange-400 text-white' onClick={() => setTakeActive(true)}>Оставить заявку</button>
                        </div>
                    </Modal>
                </div>
                <ul className='flex justify-between gap-2 max-w-[739px] w-full mobile:hidden
                1250:text-xs
                1250:justify-around
                ' >
                    <li><Link to="home" smooth={true} duration={500}>О Компании</Link></li>
                    <li><Link to="Mission" smooth={true} duration={500}>Миссия и ценности</Link></li>
                    <li><Link to="Services" smooth={true} duration={500}>Услуги</Link></li>
                    <li><Link to="Products" smooth={true} duration={500}>Продукт</Link></li>
                    <li><Link to="Partners" smooth={true} duration={500}>Партнеры</Link></li>
                    <li><Link to="Representatives" smooth={true} duration={500}>Представители</Link></li>
                </ul>
                <div className='flex items-center gap-3 mobile:hidden
                1250:text-xs
                '>
                    <div><img className='1250:w-4
                    1250:h-4' src={Global}/></div>
                    <div>rus</div>
                </div>
                <button className='max-w-[184px] w-full h-11 rounded-2xl bg-orange-400 text-white mobile:hidden
                1250:w-[124px]
                1250:h-8
                1250:text-xs
                1250:rounded-xl
                ' onClick={() => setTakeActive(true)}>Оставить заявку</button>
                <TakeModal className="mobile:hidden" active ={takeActive} setActive={setTakeActive}>
                    <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-8 px-6 py-12' >
                        <p className='text-xl font-bold'>
                            Запрос консультации
                        </p>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm font-semibold'>Имя</p>
                            <input onChange={(e) => setName(e.target.value)} ref={textRef} value={name} className='w-full h-11 border border-gray-400 rounded-xl pl-4' placeholder='Напишите Ваше имя' type='text'/>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm font-semibold'>Номер телефона</p>
                            <input onChange={(e) => setMobile(e.target.value)} ref={textRef2} value={mobile} className='w-full h-11 border border-gray-400 rounded-xl pl-4' placeholder='+7 707 777 77-77' type='text'/>
                        </div>
                        <p className='text-xs '>
                            Нажимая на кнопку "Получить консультацию", я даю Согласие на обработку Персональных данных и принимаю Политику обработки Персональных данных.
                        </p>
                        {/* <input className='border border-blue-300 rounded-lg h-12 cursor-pointer' type="submit" value="Отправить"  /> */}
                        <button type='submit' className='w-[288px] h-[45px] bg-[#FF9400] text-white flex justify-center items-center rounded-xl self-center'>
                            Получить консультацию
                        </button>
                    </form>
                </TakeModal>
            </div>
        </div>
    </header>
  );
}

export default Header;
