import React, {useRef, useEffect} from 'react';
import World from '../assets/World.svg'
import Karl from '../assets/logo-karl storz.png'
import Hilbro from '../assets/logo-hilbro.png'
import Humasis from '../assets/logo-humasis.png'
import D from '../assets/D.png'
import { Element, animateScroll as scroll } from 'react-scroll';
const Representatives = () => {
    const carouselRef = useRef(null);

  useEffect(() => {
    const scroll = () => {
      if (carouselRef.current) {
        if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 2) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft += 1;
        }
      }
    };

    const intervalId = setInterval(scroll,20);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='flex flex-col gap-12 px-10 py-20 mobile:px-4'>
        <div className='flex flex-col gap-6'>
            <div className='flex gap-2'>
                <img src={World}/>
                    <p className='font-bold text-4xl mobile:text-xl'>
                        <Element name='Representatives'>
                            Представители
                        </Element>
                    </p>
                </div>
                <p className='text-2xl mobile:text-sm'>Как официальные представители этих компаний, мы предоставляем нашим клиентам доступ к передовым технологиям и продуктам, которые соответствуют самым высоким стандартам качества и эффективности.</p>
        </div>
        <div className="">
            <div
            
                className="flex justify-evenly overflow-y-hidden"
                style={{ willChange: 'transform' }}
            >
                <img src={Karl} alt="QazMedPro" className="h-20 mx-2 " />
                <img src={Hilbro} alt="Numetek Group" className="h-20 mx-2 " />
                <img src={Humasis} alt="AGP" className="h-20 mx-2  " />
                <img src={D} alt="AGP" className="h-20 mx-2" />
            </div>
        </div>
    </div>
  );
}

export default Representatives;
