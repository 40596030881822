import './App.css';
import Header from './Header/Header';
import InfTechnologies from './InfTechnologies/InfTechnologies';
import AboutCompany from './AboutCompany/AboutCompany';
import Mission from './Mission&Value/Mission';
import Products from './Products/Products';
import MoreInf from './MoreInformaion/MoreInf';
import Partners from './Partners/Partners';
import Representatives from './Representatives/Representatives';
import Footer from './Footer/Footer';
function App() {
  return (
    <div className='bg-[#F9FCFF] py-6'>
      <div className='px-10 py-10 mobile:px-4 mobile:py-0 mobile:pt-4'>
        <Header />
        <InfTechnologies />
      </div>
      <div className=' bg-[#FFE9CA] rounded-xl h-auto'>
        <div className='px-10 py-10'>
          <AboutCompany />
        </div>
      </div>
      <div className='px-10 py-12 mobile:px-4'>
        <Mission />
      </div>
      <Products/>
      <MoreInf/>
      <Partners/>
      <Representatives/>
      <Footer/>
    </div>
  );
}

export default App;