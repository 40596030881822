import React, { useState } from 'react';
import imAboutUs from '../assets/im_AboutUs.png';
import logo from '../assets/branding_logo.svg';
import Ainur from '../assets/Image Ainur.png';
import Bytes from '../assets/Bytes.svg';
// import Cub from '../assets/im_Cub.png';
import ai from '../assets/im_ai.png'
import lessTime from '../assets/im_lessTime.png'
import treatment from '../assets/im_treatment.png'
import allInOne from '../assets/im_all-in-one.png'
import { Element } from 'react-scroll';
const AboutCompany = () => {
  const [isOpen, setIsOpen] = useState({
    // winner: false,
    treatment: true,  // Устанавливаем в true, чтобы этот раздел был открыт по умолчанию
    ai: false,
    lessTime: false,
    allInOne: false,
  });

  const toggleOpen = (section) => {
    setIsOpen((prev) => {
      const newState = {
        // winner: false,
        treatment: false,
        ai: false,
        lessTime: false,
        allInOne: false,
      };
      newState[section] = !prev[section];
      return newState;
    });
  };

  return (
    <div className='flex flex-col gap-12'>
      {/* ... остальной код */}
      <div className='flex justify-between lg:space-x-6 mobile:flex-wrap mobile:flex-col-reverse mobile:gap-4'>
        <div className='lg:w-1/2 overflow-y-[444px] max-h-[500px] p-2'>
          <ul className='list-none list-inside space-y-4'>
            {/* Удален комментарий на winner */}
            <li className='flex flex-col gap-4' onClick={() => toggleOpen('treatment')}>
              <p className='font-bold text-3xl'>Меньше затрат на лечение</p>
              {isOpen.treatment && (
                <p>
                  Наши инновационные подходы и технологии позволяют снизить расходы на лечение, обеспечивая при этом высококвалифицированное обслуживание и эффективное лечение.
                </p>
              )}
            </li>
            <li className='flex flex-col gap-4' onClick={() => toggleOpen('ai')}>
              <p className='font-bold text-3xl'>Экспертиза при помощи ИИ</p>
              {isOpen.ai && (
                <p>
                  Наши алгоритмы ИИ обрабатывают медицинские данные с высокой скоростью и точностью, помогая врачам принимать оптимальные решения для каждого пациента.
                </p>
              )}
            </li>
            <li className='flex flex-col gap-4' onClick={() => toggleOpen('lessTime')}>
              <p className='font-bold text-3xl'>Сокращаем время лечения</p>
              {isOpen.lessTime && (
                <p>
                  Мы стремимся сократить время, необходимое для диагностики и лечения, чтобы вы могли быстрее вернуться к нормальной жизни.
                </p>
              )}
            </li>
            <li className='flex flex-col gap-4' onClick={() => toggleOpen('allInOne')}>
              <p className='font-bold text-3xl'>Системы all-in-one</p>
              {isOpen.allInOne && (
                <p>
                  Наши комплексные системы all-in-one объединяют различные медицинские сервисы, от диагностики до лечения и последующего мониторинга, в единое решение.
                </p>
              )}
            </li>
          </ul>
        </div>
        <div>
          {isOpen.ai && (
            <img src={ai} alt="AI Image" className='rounded-xl max-w-[550px] w-full h-[444px]'/>
          )}
          {isOpen.treatment && (
            <img src={treatment} alt="Treatment Image" className='rounded-xl max-w-[550px] w-full h-[444px]'/>
          )}
          {isOpen.lessTime && (
            <img src={lessTime} alt="Less Time Image" className='rounded-xl max-w-[550px] w-full h-[444px]'/>
          )}
          {isOpen.allInOne && (
            <img src={allInOne} alt="All In One Image" className='rounded-xl max-w-[550px] w-full h-[444px]'/>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutCompany;
