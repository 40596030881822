import React, { useState } from 'react';
import Case from '../assets/doctor_briefcase.svg'
import Notif from '../assets/Notifications.svg'
import { Element, animateScroll as scroll } from 'react-scroll';

const Mission = () => {
    const [openSection, setOpenSection] = useState('innovation'); // Устанавливаем начальное состояние в 'innovation'

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    }

    const dataCard = [
        { number: 1, name: 'Научно-техническая деятельность.', option: 'Превращаем научные исследования в передовые медицинские решения, повышающие качество жизни пациентов' },
        { number: 2, name: 'Коммерческая разработка', option: 'Разрабатываем и внедряем передовые IT-продукты, которые трансформируют медицинскую отрасль и улучшают работу медицинских учреждений' },
        { number: 3, name: 'Поставка медицинского оборудования', option: 'Обеспечиваем медицинские учреждения самым современным оборудованием для точной диагностики и эффективного лечения' }
    ];

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4 '>
                <img src={Case} alt="Doctor's briefcase" />
                <p className='text-4xl font-bold mobile:text-xl'>Миссия и ценности</p>
            </div>
            <div className='max-w-[900px]'>
                <p className='text-4xl font-semibold mobile:text-xl'>
                    <Element class='Mission' name="Mission">
                        Наша миссия — разрабатывать инновационные медицинские продукты, которые делают здравоохранение более
                        <span className='text-[#FF9400]'> эффективным, доступным и ориентированным на пациента</span>
                    </Element>
                </p>
            </div>
            <div className='flex justify-around'>
                <div className='max-w-[80px] w-full max-h-[180px] h-full'>
                    {/* fgh */}
                </div>
                <div className='flex flex-col gap-6'>
                    <div className='flex justify-start gap-6 mobile:gap-4 '>
                        <div>
                            <div
                                className={`flex flex-col items-center justify-center gap-4 cursor-pointer ${openSection === 'innovation' ? 'text-orange-400 border-orange-400' : 'text-gray-500 border-gray-500'}`}
                                onClick={() => toggleSection('innovation')}
                            >
                                <p className='px-4 h-12 border flex justify-center items-center rounded-2xl w-auto text-2xl mobile:text-sm mobile:h-[30px]'>
                                    Инновация
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                className={`flex flex-col gap-4 items-center justify-center cursor-pointer ${openSection === 'efficiency' ? 'text-orange-400 border-orange-400' : 'text-gray-500 border-gray-500'}`}
                                onClick={() => toggleSection('efficiency')}
                            >
                                <p className='h-12 border flex justify-center items-center rounded-2xl w-auto text-2xl px-4 mobile:text-sm mobile:h-[30px]'>
                                    Эффективность
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                className={`flex flex-col gap-4 items-center justify-center cursor-pointer ${openSection === 'accessibility' ? 'text-orange-400 border-orange-400' : 'text-gray-500 border-gray-500'}`}
                                onClick={() => toggleSection('accessibility')}
                            >
                                <p className='h-12 border flex justify-center items-center rounded-2xl w-auto text-2xl px-4 mobile:text-sm mobile:h-[30px]'>
                                    Доступность
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='max-w-[900px] flex self-center'>
                        {openSection === 'innovation' && (
                            <p className='text-2xl font-normal'>
                                Мы стремимся к постоянному развитию и внедрению передовых технологий, чтобы предложить медицинские продукты, которые отвечают самым современным требованиям и решают актуальные проблемы здравоохранения. Инновации лежат в основе нашей работы, обеспечивая прогрессивные решения и улучшение качества медицинского обслуживания.
                            </p>
                        )}
                        {openSection === 'efficiency' && (
                            <p className='text-2xl font-normal'>
                                Наши продукты ориентированы на повышение эффективности медицинских процессов и улучшение результатов лечения. Мы создаем решения, которые помогают медицинским специалистам работать более продуктивно, а пациентам — получать более качественную помощь.
                            </p>
                        )}
                        {openSection === 'accessibility' && (
                            <p className='text-2xl font-normal'>
                                Мы считаем, что передовые медицинские технологии должны быть доступны каждому. Поэтому мы сосредоточены на создании решений, которые помогают расширить доступ к качественной медицинской помощи и сделать ее более доступной для различных групп населения.
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className='flex gap-4 items-center mt-8'>
                <img src={Notif} alt="Notification Icon" />
                <p className='text-4xl font-bold'>
                    <Element class='Services' name="Services">
                        Услуги
                    </Element>
                </p>
            </div>
            <div className='grid grid-cols-3 gap-2 mobile:grid-cols-1 mobile:gap-4'>
                {dataCard.map((title) => (
                    <div
                        key={title.number}
                        className='flex flex-col justify-between max-w-[420px] w-full min-h-[380px] mobile:min-h-[280px] mobile:gap-6 border border-orange-200 bg-[#FFFFF] rounded-2xl mobile:w-full'
                    >
                        <div className='flex justify-center items-center text-5xl font-bold text-white w-20 h-20 rounded-[48px] bg-orange-400 m-2'>
                            {title.number}
                        </div>
                        <div className='flex flex-col justify-between h-[196px] mb-8 gap-4 px-4 '>
                            <p className='text-3xl font-bold '>{title.name}</p>
                            <p className='text-xl font-normal'>{title.option}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Mission;
